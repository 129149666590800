import React from "react"
import { graphql } from "gatsby"
import Layout from "./layout"
import SEO from "./seo"

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  const body =  <div
  className="blog"
  dangerouslySetInnerHTML={{ __html: html }}
/>
  return (
    <Layout body={body} subtitle={frontmatter.subtitle} title={frontmatter.title} hideChat={true}>
              <SEO
                title={frontmatter.title}
                keywords={[`clean`, `code`, `solid`, `tdd`, `unit`, `functional`, `abstraction`, `compose`]}
            />

      </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        slug
        title
        subtitle
      }
    }
  }
`